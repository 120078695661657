import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nenalezeno" />
    <h1>Nenalezeno</h1>
  </Layout>
);

export default NotFoundPage;
